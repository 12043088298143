@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    width: 100%;
    overflow-x: hidden;
}

.hero {
    background-image: url('./../public/background.jpg');
    background-size: cover;
    background-repeat:  no-repeat;

}

